import i18next from "i18next";
import LaguangeSelect from "../LanguageSelect";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {zonaHuayacanLinks} from "@common/links";
import { Facebook, Instagram, Tiktok, Youtube, Envelope, MapMarker } from "@common/Icons"; 
import { Copyright } from 'lucide-react';

const Footer = () => {
    const [t] = useTranslation("global");
    const [originLink, setOriginLink] = useState("https://www.originbywolf.com");
    const [wolfLink, setWolfLink] = useState("https://wolftowers.com/");

    const [links, setLinks] = useState(zonaHuayacanLinks.es);
    
    useEffect(() => {
        i18next.language === "en" ? setOriginLink("https://originbywolf.com/en/") : setOriginLink("https://originbywolf.com");
        const selectedLinks = i18next.language === "en" ? zonaHuayacanLinks.en : zonaHuayacanLinks.es;
        setLinks(selectedLinks);
    }, [i18next.language]);

    return <>
        <div className="fotter bg-black p-4 py-12">
            <div className="h-auto md:h-[10em] w-full py-4 md:py-0 text-white grid md:grid-flow-row md:grid-cols-3 grid-cols-none items-center gap-4">
                <div className="md:flex items-center justify-center hidden h-full gap-4">
                    <a href={wolfLink}>
                        <img src="/images/wolf-logo.png" alt="logo" className='w-20' />
                    </a>
                    <a href={originLink}>
                        <img src="/images/origin-logo.png" alt="logo" className='w-32' />
                    </a>
                </div>

                <div className="flex h-full items-center">
                    <div className="h-[50%] w-[1px] bg-[#9B8665] hidden sm:block"></div>
                    <div className="flex flex-col mx-auto justify-center h-full px-0 md:px-10">
                        <p className='text-[#9B8665] font-semibold md:text-left text-center mb-2'>{t("footer.labels.contact")}</p>
                        <p className="mb-2">
                            <a href="mailto:comercial@wolftowers.com">
                                <Envelope className="w-6 h-6 text-[#9B8665] mr-1 inline-block" />
                                comercial@wolftowers.com
                            </a>
                        </p>
                        <p className='flex'>
                            <MapMarker className="w-6 h-6 text-[#9B8665] mr-1 inline-block" />
                            <span>Plaza Wolf. Avenida Colegios,<span className='block'>L13 SM 307 MZ 49</span></span>
                        </p>
                        <div className='grid md:grid-cols-6 grid-cols-4 mx-auto gap-3 mt-3'>
                            <span className='bg-white rounded-full w-7 h-7 flex justify-end items-end md:mx-[inherit] mx-auto'>
                                <a href="https://www.facebook.com/OriginByWolf/" target="_blank" rel="noreferrer" className="relative top-0.5 left-0.5">
                                    <Facebook className={"text-black"} />
                                </a>
                            </span>
                            <span className='bg-white w-7 h-7 flex items-center justify-center rounded-full md:mx-[inherit] mx-auto'>
                                <a href="https://www.instagram.com/originbywolf/" target="_blank" rel="noreferrer">
                                    <Instagram className={"text-black"} />
                                </a>
                            </span>
                            <span className='bg-white w-7 h-7 flex items-center justify-center rounded-full md:mx-[inherit] mx-auto'>
                                <a href="https://www.tiktok.com/@originbywolf" target="_blank" rel="noreferrer">
                                    <Tiktok className={"text-black"} />
                                </a>
                            </span>
                            <span className='bg-white w-7 h-7 flex items-center justify-center rounded-full md:mx-[inherit] mx-auto'>
                                <a href="https://www.youtube.com/@originbywolf1988" target="_blank" rel="noreferrer">
                                    <Youtube className={"text-black"} />
                                </a>
                            </span>
                        </div>
                    </div>
                    <div className="h-[50%] w-[1px] bg-[#9B8665] hidden sm:block"></div>
                </div>

                <div className="flex flex-col gap-2 justify-between md:justify-center h-full mx-auto px-5 w-full col-span-full md:col-auto">
                    <p className='text-[#9B8665] font-semibold md:text-left text-center'>{t("footer.labels.follow")}</p>
                    <div className='grid md:grid-cols-6 grid-cols-4'>
                        <span className='bg-white rounded-full w-7 h-7 flex justify-end items-end md:mx-[inherit] mx-auto'>
                            <a href={links.facebook} target="_blank" rel="noreferrer" className="relative top-0.5 left-0.5">
                                <Facebook className="text-black" />
                            </a>
                        </span>
                        <span className='bg-white w-7 h-7 flex items-center justify-center rounded-full md:mx-[inherit] mx-auto'>
                            <a href={links.instagram} target="_blank" rel="noreferrer">
                                <Instagram className="text-black" />
                            </a>
                        </span>
                        <span className='bg-white w-7 h-7 flex items-center justify-center rounded-full md:mx-[inherit] mx-auto'>
                            <a href={links.tiktok} target="_blank" rel="noreferrer">
                                <Tiktok className='text-black' />
                            </a>
                        </span>
                        <span className='bg-white w-7 h-7 flex items-center justify-center rounded-full md:mx-[inherit] mx-auto'>
                            <a href={links.youtube} target="_blank" rel="noreferrer">
                                <Youtube className="text-black" />
                            </a>
                        </span>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-2 md:flex-row justify-center md:justify-around items-center w-full">
                <div>
                    <span className="text-white text-left md:text-center flex items-center gap-1"><Copyright size={15} /> {new Date().getFullYear()} - Origin By Wolf</span>
                </div>
                <LaguangeSelect />
            </div>
        </div>
    </>
}

export default Footer;