import React, { useEffect, useRef, useState } from 'react';

const VideoResponsive = ({ videoVertical, videoHorizontal }) => {
    const [orientation, setOrientation] = useState("landscape");

    useEffect(() => {
        const handleResize = () => {
            // Detectar orientación basada en ancho y alto de la ventana
            const isPortrait = window.innerHeight > window.innerWidth;
            //Orientación vertical (Portrait Orientation)
            //Orientación horizontal (Landscape Orientation)
            setOrientation(isPortrait ? "portrait" : "landscape");
        };

        // Detectar orientación inicial
        handleResize();

        // Agregar evento de cambio de tamaño
        window.addEventListener("resize", handleResize);

        return () => {
            // Limpiar evento al desmontar componente
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const videoRef = useRef(null);

    useEffect(() => {
        const handleFullscreen = () => {
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            if (isMobile && videoRef.current) {
                const video = videoRef.current;

                // Solicitar pantalla completa en móviles
                if (video.requestFullscreen) {
                    video.requestFullscreen();
                } else if (video.webkitEnterFullscreen) {
                    video.webkitEnterFullscreen(); // Método específico para Safari en iOS
                } else if (video.mozRequestFullScreen) {
                    video.mozRequestFullScreen();
                } else if (video.msRequestFullscreen) {
                    video.msRequestFullscreen();
                }

                // Reproducir automáticamente
                video.play().catch((error) => {
                    console.error("No se pudo reproducir el video:", error);
                });
            }
        };

        if (videoRef.current) {
            // Llamar a fullscreen al cargar
            //handleFullscreen();
            
            videoRef.current.addEventListener("load", handleFullscreen);
        }
        return () => {
            // Limpiar el evento al desmontar el componente
            if (videoRef.current) {
                videoRef.current.removeEventListener("load", handleFullscreen);
            }
        };
    }, []);
    return <video
        ref={videoRef}
        src={(orientation=="landscape"&&videoHorizontal)?videoHorizontal:videoVertical}
        controls
        autoPlay
        className="w-full h-full mx-auto aspect-video relative"
    />;
};

export default VideoResponsive;