import { useTranslation } from "react-i18next";

const MobileHome = ({goto}) => {
    const [t] = useTranslation("global");
    return <>
        <div className="grid grid-flow-row relative h-screen after:absolute after:top-0 after:w-full after:h-full after:from-30% after:bg-gradient-to-t after:from-[rgba(0,0,0,.6)] after:to-transparent bg-[url(https://zonahuayacan.com.mx/images/PHOTO-2024.jpg)] bg-center bg-cover bg-no-repeat">
            <div className="z-10 flex flex-col justify-around p-6">
                <div className="flex-1">
                    <h1 className="text-black mt-8 text-2xl">STEP <span className="text-[#A78E64]">BEYOND</span> <span className="font-bold">LIVING</span></h1>
                </div>
                <div className="w-full">
                    <img src="/logo.png" alt="logo" className="w-[90%] mx-auto" />
                </div>
                <div className="flex justify-center py-10">
                    <div className="p-2" style={{backgroundImage: "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%239B8665FF' stroke-width='2' stroke-dasharray='12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e\")",borderRadius: '100px'}}>
                        <button onClick={goto} className="bg-[#9B8665] text-white flex items-center justify-center p-2 rounded-full">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6l6 6l-6 6" /></svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default MobileHome;