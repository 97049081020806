import { useEffect, useRef, useState } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import mapboxgl from 'mapbox-gl';
import { modalStore } from "../store/modalsStore";
import useElementIsOffset from "./hooks/useElementIsOffset";
import { menuStore } from "../store/menuStore";
import { isMobile } from "react-device-detect";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export default ({ mapRef, mapMoveTo }) => {
    const [locations, setLocations] = useState([]);
    const itemsRef = useRef([]);
    const [mapLoaded, setMapLoaded] = useState(false);
    //const mapRef = useRef();
    const mapContainerRef = useRef();
    const locationsRef = useRef();
    const [t, i18next] = useTranslation("global");
    const { setActiveIndex, setPlaces } = menuStore();
    const { events } = useDraggable(locationsRef, {
        applyRubberBandEffect: true,
    });
    const { toggleModal } = modalStore();
    //const { ref, isSticky } = useSticky();
    const offset = useElementIsOffset(locationsRef);
    const started = useRef();

    const handleScroll = () => {
        if(!isMobile) return;
        //Se obtiene el centro de contenedor del scroll
        const container = locationsRef.current;
        const containerCenter = container.scrollLeft + container.offsetWidth / 2;

        //Se recorren todos elementos del scroll
        itemsRef.current.forEach((item, index) => {
            if (item) {
                //Se obtiene el centro de un elemento del scroll
                const itemCenter = item.offsetLeft + item.offsetWidth / 2;
                //Se obtiene el id del elemento que esta en centro para hacer zoom en el mapa
                const itemId = item.dataset.id;
                // Define un rango de tolerancia
                const tolerance = 60; // Mantén la clase mientras el elemento esté dentro de este rango

                // Encuentra el elemento más cercano al centro del contenedor
                if (Math.abs(containerCenter - itemCenter) < tolerance && !item.classList.contains('centered')) {
                    //Se obtiene el elemto que esta en el centro para extraer sus coordenadas por medio del itemId
                    const location = locations.filter(locationItem => locationItem.key == itemId);
                    item.classList.add('centered');
                    if (location[0]) {
                        //Si se obtuvo algun elemento del scroll se aplica el zoom en el mapa
                        const { long, lat } = location[0].location

                        mapMoveTo(long, lat);
                    }
                } else if (Math.abs(containerCenter - itemCenter) > tolerance * 1.5) {
                    // Solo elimina la clase si está completamente fuera de un rango extendido
                    item.classList.remove("centered");
                }
            }
        });
    };

    useEffect(() => {
        const load = async () => {
            //Se obtienen todas la ubicaciones por medio de un fetch hacia un archivo dentro del mismo servidor
            await fetch('/locations.json?ver=0.7').then(res => res.json()).then(result => {
                if (result?.locations) {
                    //En caso de que si se encuentren ubicaciones se pasa a un estado del componente
                    setLocations(result.locations);
                    setPlaces(result.locations);
                    //Se pasa el token a MapBox
                    mapboxgl.accessToken = 'pk.eyJ1IjoibWFudWVsaHMiLCJhIjoiY200M2NrbW93MGFoNDJpcHZ3eGhxOWFkbSJ9.6FGlvB8KYqXwbf2rTtS5-w'
                    mapRef.current = new mapboxgl.Map({
                        minZoom: 12,
                        container: mapContainerRef.current,
                        center: [-86.8610347, 21.0988501],
                        zoom: 15,
                        style: 'mapbox://styles/mapbox/streets-v12',
                    });
                    mapRef.current.on('load', () => {
                        setMapLoaded(true);
                        started.current = true;
                        handleScroll();
                    });
                    mapRef.current.on('error', error => {
                        setMapLoaded(true);
                        console.log(error);
                        toast.error("Error al cargar el mapa.")
                    })
                }
            });

        }
        load();
        if (locationsRef?.current && mapLoaded) {
            locationsRef?.current?.addEventListener('scrollLeft', handleHorizantalScroll);

        }


        return () => {
            //if (mapRef?.current) {
            //    try {
            //        mapRef.current.remove();
            //    } catch (error) {
            //        console.error(error);
            //    }
            //}
            if (locationsRef?.current && mapLoaded) {
                try {
                    locationsRef?.current?.removeEventListener('scrollLeft', handleHorizantalScroll);
                } catch (error) {
                    console.log(error);

                }
            }
        }
    }, []);

    useEffect(()=>{
        if(mapLoaded){
            locations.map((slide, index) => {
                const el = document.createElement('div');
                el.classList.add(slide.key == 1 ? 'origin-marker' : 'circle');
                el.classList.add('cursor-pointer');
                el.addEventListener('click', (el) => handleClickMarker(slide));
                el.dataset.id = slide.key;
                const { long, lat } = slide.location;
                new mapboxgl.Marker(el).setLngLat([long, lat]).addTo(mapRef.current);
            });
        }
    },[mapLoaded]);

    const handleClickMarker = (data) => {
        toggleModal(true, data, 'gallery');
    }

    const handleHorizantalScroll = (element, speed, distance, step) => {
        let scrollAmount = 0;

        const slideTimer = setInterval(() => {
            element.scrollLeft += step;
            scrollAmount += Math.abs(step);
            if (scrollAmount >= distance) {
                clearInterval(slideTimer);
            }
            if (element.scrollLeft === 0) {
                //setArrowDisable(true);
            } else {
                //setArrowDisable(false);
            }
        }, speed);
    };

    const handleRoggleModal = (e, slide, tab) => {
        e.preventDefault();
        toggleModal(true, slide, tab)
    }

    return <>
        <div className={`h-full relative`}>
            <div id='map-container' className="w-full h-full" ref={mapContainerRef} />
            {
                !mapLoaded &&
                <div className="h-full flex items-center justify-center bg-transparent before:bg-cover before:bg-center before:bg-[url('https://zonahuayacan.com.mx/images/map.jpg')] before:blur-sm before:absolute before:top-0 before:left-0 before:right-0 before:w-full before:h-full">
                    <div className="w-full h-full flex items-center justify-center absolute top-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-loader-2 animate-spin text-4xl text-[#9B8665]"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 3a9 9 0 1 0 9 9" /></svg>
                    </div>
                </div>
            }
            <div className={`absolute bottom-8 ${''/*left-auto sm:left-0*/} sm:bottom-12 w-full locations ${!mapLoaded ? 'invisible' : 'visible'}`}>
                <div {...events} ref={locationsRef} onScroll={handleScroll} className="overflow-x-scroll overflow-y-hidden sm:scroll-auto locatioins-container relative pb-2 z-30">
                    <div className="flex items-center gap-x-4 sm:gap-x-0 mx-2 h-64">
                        <div className="md:ml-[calc(100%-80%)] ml-[calc(100%-theme(space.24))] -z-50 block md:hidden"></div>
                        <div className="h-auto md:h-full snap-end snap-always mr-0 sm:mr-2 relative top-0">
                            <div className="w-20 md:w-24 h-full" >
                                <img src={`https://zonahuayacan.com.mx/images/Frame-2.png`} alt="" className="w-full" />
                            </div>
                        </div>
                        <div>
                            <div className="h-full flex flex-row gap-x-2 [&_.centered]:scale-[1.2] [&_.centered]:shadow-[0px_0px_20px_-10px_black]">
                                {locations && locations.map((slide, index) => {
                                    const { long, lat } = slide.location;

                                    return <div key={slide.key}>
                                        <div data-id={slide.key} ref={(el) => (itemsRef.current[index] = el)} className={`item bg-transparent rounded-xl transition-transform duration-500 scale-1 mx-3 sm:mx-0`}>
                                            <div className={`flex flex-col h-48 w-48 rounded-xl overflow-hidden cursor-pointer bg-white`} onClick={() => mapMoveTo(long, lat)}>
                                                <div className="bg-cover bg-no-repeat bg-center w-full flex-1" style={{
                                                    backgroundImage: `url(${slide?.urlCardImage})`
                                                }}></div>
                                                <div className="bg-black flex items-center gap-2 h-[80px] px-2">
                                                    <div className="w-[70%]">
                                                        <p className="text-white text-xs">
                                                            <span className="text-[#9B8665] font-bold">{slide.replace}</span>{slide.name.replace(slide.replace, '')}
                                                        </p>
                                                        <p className="text-white text-[.7em] wrap-pharagraph">
                                                            {slide.address}
                                                        </p>
                                                    </div>
                                                    <div className="flex gap-4 items-center w-[30%]">
                                                        <button onClick={e => handleRoggleModal(e, slide, 'gallery')}>
                                                            <img src="/images/icons/photo-icon2.png" alt="photo-icon" className="w-4" />
                                                        </button>
                                                        <button onClick={e => handleRoggleModal(e, slide, 'video')}>
                                                            <img src="/images/icons/video-icon2.png" alt="photo-icon" className="w-4" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            locations.length == index + 1 &&
                                            <div className="h-auto md:h-full ml-2 w-[50vw] sm:w-auto"></div>
                                        }
                                    </div>
                                })}
                            </div>
                            <div className={`bottom-1 text-[#9B8665] sm:flex gap-1 hidden ${offset ? 'pt-1.5 pl-1' : 'pt-1'}`} style={offset ? { position: "sticky", left: 0, width: '80px' } : {}}>
                                <button className="bg-black rounded-full" onClick={() => handleHorizantalScroll(locationsRef.current, 25, 100, -15)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-chevron-left"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M15 6l-6 6l6 6" /></svg>
                                </button>
                                <button className="bg-black rounded-full" onClick={() => handleHorizantalScroll(locationsRef.current, 25, 100, 15)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 6l6 6l-6 6" /></svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}